import { render, staticRenderFns } from "./Window.vue?vue&type=template&id=959f7fe6&scoped=true"
import script from "./Window.vue?vue&type=script&lang=ts"
export * from "./Window.vue?vue&type=script&lang=ts"
import style0 from "./Window.vue?vue&type=style&index=0&id=959f7fe6&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "959f7fe6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ScrollArea: require('/artifacts/BGFrontend/components/ScrollArea.vue').default})
