// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ui/currencyIcon/credits.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/ui/currencyIcon/gold.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root_div[data-v-76059d41]{overflow:visible;text-align:center;vertical-align:middle}.root_div .currency_icon[data-v-76059d41]{background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:auto 400%;display:inline-block;flex-shrink:0;height:100%;margin-bottom:.1em;min-height:1em;min-width:1em;text-align:center;vertical-align:top;width:auto}.root_div .currency_icon[data-v-76059d41],.root_div .currency_icon.currency_style_0[data-v-76059d41]{background-position:center 0}.root_div .currency_icon.currency_style_1[data-v-76059d41]{background-position:center 33.3333%}.root_div .currency_icon.currency_style_2[data-v-76059d41]{background-position:center 66.6666%}.root_div .currency_icon.currency_style_3[data-v-76059d41]{background-position:center 100%}.root_div .currency_icon.currency_Cr[data-v-76059d41]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.root_div .currency_icon.currency_GLD[data-v-76059d41]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
