

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { EAwardsList } from '../../Ui/Award.vue';
import { isMobile } from 'mobile-device-detect';

@Component({ })
export default class WindowAwardsGallery extends Vue {
    isMobile:boolean = isMobile 
    awards:object[] = EAwardsList
}
