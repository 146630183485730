
import { Vue, Component, Prop } from 'vue-property-decorator'
import {ECurrencyAbbreviation} from '~/modules/model/Types/Currency'
import { translate_currency } from '~/modules/utility/utils'

@Component({})
export default class MoneyLabel extends Vue {
    @Prop({ type:String ,   default: ""     })  readonly uuid       !: string
    @Prop({ type:String ,   default: ""     })  readonly value      !: string
    @Prop({ type:String ,   default: ""     })  readonly code       !: string
    @Prop({ type:Boolean ,  default: true   })  readonly isAfter    !: boolean
    @Prop({ type:Boolean ,  default: true   })  readonly isRank     !: boolean
    @Prop({ type:Boolean ,  default: false  })  readonly hideEmpty  !: boolean
    @Prop({ type:Number ,   default: 0      })  readonly color      ?: number

    get valueString() {
        return ''+

                    (
                        this.isRank?
                            translate_currency( this.value, this.$i18n.locale ).replace(/\B(?=(\d{3})+(?!\d))/g, " "):
                            translate_currency( this.value, this.$i18n.locale )
                    )+ 
                    (
                        this.swapToIcon ? 
                            '' : 
                            " " +translate_currency( this.code, this.$i18n.locale ) 
                    );
    }

    get isHidden () {
        return (this.hideEmpty === true && parseFloat(this.value) === 0);
    }

    get swapToIcon() {
        return [
            ECurrencyAbbreviation.CREDIT, 
            ECurrencyAbbreviation.GOLD
        ]
            .map((elem) => elem.toString())
            .includes(this.code);
    }

    get backgroundIconImg() {
        let img:string = '';
        switch(this.code) {
            case ECurrencyAbbreviation.CREDIT:
                img = 'url(\'~assets/images/ui/currencyIcon/credits.png\')';
                break
            case ECurrencyAbbreviation.GOLD:
                img = 'url(\'~assets/images/ui/currencyIcon/gold.png\')';
                break
            default: 
                img = ''
        }
        return img
    }

    get codeString() {
        switch(this.code) {
            case ECurrencyAbbreviation.CREDIT:
                return 'Cr'
                break
            case ECurrencyAbbreviation.GOLD:
                return 'GLD'
                break
            default: 
                return ''
        }
    }
}
