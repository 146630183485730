

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { EAwardsList } from '../../Ui/Award.vue';
import { isMobile } from 'mobile-device-detect';

@Component({})
export default class WindowAwardsPlayer extends Vue {
    isMobile:boolean = isMobile 

    @Prop({ type: Array, default: [], required: false }) awards !: string[]

    title (id: string) {
        let out = '';
        EAwardsList.forEach(
            (laward:{id:string, caption:string})=> {
                if (laward.id === id)
                    {
                        out = laward.caption;
                        return false;
                    }
            }
        );
        return out;
    }
}
