
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
    
enum ETabName {
    PLAYER = "player",
    GALLERY = "gallery" 
}

@Component({
    computed: { 
    }
})
export default class WindowAwards extends Vue {    
    tabId:string = ETabName.PLAYER;

    @Prop({ type: Array, default: [], required: false }) awards !: string[]
    
    get tabs() { 
        let result:any = {};       
        result[ETabName.PLAYER] = {caption: this.$t('Awards.tab_PLAYER') } 
        result[ETabName.GALLERY] = {caption: this.$t('Awards.tab_GALLERY') } 
        
        return result;    
    }
}
