import { render, staticRenderFns } from "./Awards.vue?vue&type=template&id=6eee3d88&scoped=true"
import script from "./Awards.vue?vue&type=script&lang=ts"
export * from "./Awards.vue?vue&type=script&lang=ts"
import style0 from "./Awards.vue?vue&type=style&index=0&id=6eee3d88&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eee3d88",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AwardsGallery: require('/artifacts/BGFrontend/components/Windows/Awards/AwardsGallery.vue').default,AwardsPlayer: require('/artifacts/BGFrontend/components/Windows/Awards/AwardsPlayer.vue').default,WindowUpTabs: require('/artifacts/BGFrontend/components/WindowUpTabs.vue').default})
