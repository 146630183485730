
import { Vue, Component, Prop } from 'vue-property-decorator'
import Player from '~/modules/model/Types/Player'
import { EBotSkillLevel, EFlagColor } from '~/modules/model/Types/IPlayerSettings'

@Component({})
export default class PlayerStag extends Vue {
    @Prop(Object) player !: Player
    @Prop({ default : false }) king !: boolean    
    @Prop({ default : null }) flagColor?: EFlagColor | null
    @Prop({ default : true }) showRating?: boolean
    @Prop({ default : false }) showPipe?: boolean
    
    get name() {
        if ( ! this.player ) {
            return ''
        }
        let n = this.player.name
        console.log( 'Name:', n )
        //new moderation process required us to translate EVERYTHING, so translating "BOT NOVICE" to "Робот Новичок":
        if ( n.toLowerCase().startsWith( 'bot ' ) ) {
            n = n.substring( 4 )
            const t : { [ key : string ] : string } = {}
            t[ EBotSkillLevel.DUMMY ]           = this.$tc('Difficulty.Dummy')
            t[ EBotSkillLevel.NOVICE ]          = this.$tc('Difficulty.Novice')
            t[ EBotSkillLevel.SIMPLE ]          = this.$tc('Difficulty.Simple')
            t[ EBotSkillLevel.MIDDLE ]          = this.$tc('Difficulty.Medium')
            t[ EBotSkillLevel.ADVANCED ]        = this.$tc('Difficulty.Advanced')
            t[ EBotSkillLevel.PROFESSIONAL ]    = this.$tc('Difficulty.Professional')
            t[ EBotSkillLevel.EXPERT ]          = this.$tc('Difficulty.Expert')
            t[ EBotSkillLevel.MASTER ]          = this.$tc('Difficulty.Master')
            t[ EBotSkillLevel.CHAMPION ]        = this.$tc('Difficulty.Champion')
            t[ EBotSkillLevel.GURU ]            = this.$tc('Difficulty.Guru')
            for ( const key in t ) {
                n = n.replace( new RegExp( key, "gi" ), t[ key ] )
            }
        }
        return n
    }
    get rating() { return this.player ? this.player.rating : '' }

    get color() { 
        if(this.flagColor)
            return this.flagColor;

        let color = this.player && this.player.settings && this.player.settings.flag_color ? this.player.settings.flag_color : EFlagColor.ANY
        if(color == EFlagColor.ANY)
            color = EFlagColor.RED
        return color;        
    }
}
