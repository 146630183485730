
import { Vue, Component, Prop ,PropSync, Emit, Watch } from 'vue-property-decorator'
import { isMobile } from 'mobile-device-detect';
import { EDialogEvents } from './Dialogs.vue';

@Component({})
export default class WindowUpTab extends Vue {         

    @Prop({ default:0}) tabId!:string | number    
    @Prop() tabs!: any[]    

    @Prop({ type:Boolean, default: false }) readonly goBack!: boolean
    @Prop({ type:String , default: "/"   }) readonly backPath!: string

    onClickClose() {
        if (!isMobile) {
            this.$emit("close");
            this.$root.$emit(EDialogEvents.HIDE_ALL_DIALOGS)
            this.$router.push( this.localePath('/'));
        }
    }

    close() {
        this.$emit("close");
        if(this.backPath && this.backPath.length)
            this.$router.push( this.localePath(this.backPath));

        if(this.goBack)
            if (window.history.length > 1) {
                this.$router.go(-1)
            }
            else {
                this.$router.push('/')
            }
    }

    doSelectTab(index:string|number) {                      
        this.$emit('update:tabId', index)        
    }

    @Watch("tabId")
    onTabChanged() {
        let win = <any>this.$refs.window_internal;        
        if(win) {
            this.$nextTick( ()=>{
                win.updateScrollStatus();        
            })            
        }
    }

    created() {
       
        let tabId = <string | undefined> this.$route.query.tabId;
        if ( tabId )
            this.doSelectTab(tabId);
    }
}
