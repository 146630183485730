// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/ui/buttons/ng_btn_42.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/ui/themes/dark/borders/ng_border.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title[data-v-959f7fe6]{border-bottom:.0714em solid #ccc}.header[data-v-959f7fe6],.title[data-v-959f7fe6]{color:#eed9a0;font-size:1.7em;font-weight:700;line-height:1em;overflow:hidden;padding:.5em 0;text-align:center;text-overflow:ellipsis;text-transform:uppercase;white-space:nowrap}.button_header[data-v-959f7fe6]{height:4.5em;overflow:hidden}.aspect_ratio_box:not(.device_mobile) .button_header[data-v-959f7fe6]{height:auto}.footer[data-v-959f7fe6]{border-top:.0714em solid #ccc;bottom:0;color:#eed9a0;overflow:hidden;text-align:center;text-overflow:ellipsis;white-space:nowrap}.close_button[data-v-959f7fe6]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:3.1428em;line-height:3.1428em;padding:0;position:absolute;right:.5em;top:0;width:3.1428em}.mobile_window[data-v-959f7fe6]{background-color:rgba(0,0,0,.8);background-position:top;background-repeat:repeat;border-image-outset:0 1.3571em 0 1.5em;border-image-repeat:repeat repeat;border-image-slice:0 42 0 42 fill;border-image-source:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");border-image-width:0 1.5em 0 1.5em;box-sizing:border-box;cursor:default;height:100%;margin:0 auto;max-height:100%;max-width:100%;min-height:5em;min-width:5em;position:relative;text-align:left;width:70%;z-index:1}.aspect_ratio_box:not(.device_mobile) .mobile_window[data-v-959f7fe6]{width:43.75%}.blackout[data-v-959f7fe6]{background-color:rgba(0,0,0,.95)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
