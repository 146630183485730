import { render, staticRenderFns } from "./AwardsGallery.vue?vue&type=template&id=77529693&scoped=true&lang=pug"
import script from "./AwardsGallery.vue?vue&type=script&lang=ts"
export * from "./AwardsGallery.vue?vue&type=script&lang=ts"
import style0 from "./AwardsGallery.vue?vue&type=style&index=0&id=77529693&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77529693",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Award: require('/artifacts/BGFrontend/components/Ui/Award.vue').default})
