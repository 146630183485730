
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import Tournament, { ETournamentStatus, ITournamentWithAll } from '~/modules/model/Types/Tournament';
import { starts_in, print_timestamp, translate_tournament } from '~/modules/utility/utils';
import { EGameStatus } from '~/modules/model/Types/Game';
import { EDialogEvents } from '~/components/Dialogs.vue';
import { IGame, IGameWithPlayersMinimalAndCurrency } from "~/modules/model/Types/Game";

@Component({
    data: () => ({
        intervalTimer: null,
        timeToTour: '',
        timestamp: 0
    }),
})
export default class TournamentInfo extends Vue {
    @Prop(Object) uuid !: any
    t : Tournament | null = null
    timestamp : number = 0
    timeToTour : string = ''
    intervalTimer : any
    
    async mounted() {
        const ti = await this.$store.dispatch( 'tournament/doView', this.uuid )
        this.t = new Tournament( ti )

        console.log( 'Tournament info:', this.t )

        this.startTimer();
    }

    destroyed() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
    }

    score(game:IGameWithPlayersMinimalAndCurrency) {
        if (this.can_watch(game) && game.scoreString === "0:0")
            return "-:-"

        return game.scoreString
    }

    startTimer() {
        if ( ! this.t ) return 
        switch ( this.t.status ) {
            case ETournamentStatus.ANNOUNCED:
            case ETournamentStatus.OPEN:
                this.timestamp = this.t.start_timestamp
                break;
            case ETournamentStatus.IN_PLAY:
                this.timestamp = this.t.next_timestamp
                break
            default:
                this.timestamp = 0
        }
        if (this.t.status !== ETournamentStatus.FINISHED && this.t.status !== ETournamentStatus.CANCELED) {
            this.timeToTour = starts_in(this.timestamp, this.$tc("DaysShorthand"))
            this.intervalTimer = setInterval(() => {
                if (this.timestamp !== 0) {
                    this.timeToTour = starts_in(this.timestamp, this.$tc("DaysShorthand"))
                }
            }, 1000)
        }
    }

    orderedTourGame(tourGames:IGameWithPlayersMinimalAndCurrency[]) {
        return tourGames.slice().sort((gameA, gameB):number => {
            if (gameA.playerA.name < gameB.playerA.name) {
                return -1;
            }
            else {
                return 1;
            }
            return 0;
        })
    }

    get needShowTime() {
         if ( ! this.t ) return false;
         if (this.t.status === ETournamentStatus.IN_PLAY) {
             if (this.t.tour_count === this.t.tourCountMax) {
                 return false;
             }
         }
         return true;
    }

    get game_type() {
        if ( ! this.t ) return ''
        switch ( this.t.rules.type ) {
            case 'Long':
                return this.$tc('Long backgammon')
            case 'Short':
                return this.$tc('Short backgammon')
        }
        return 'undefined'
    }
    get name() {
        if ( ! this.t ) return ''
        return this.$tc('Game.Name') + ': ' + translate_tournament( this.t.caption, this.$i18n.locale )
    }

    get time_label() {
        if ( ! this.t ) return ''
        switch ( this.t.status ) {
            case ETournamentStatus.ANNOUNCED:
            case ETournamentStatus.OPEN:
                return this.$tc('StartsIn') + ':  ' + this.timeToTour//starts_in( this.t.start_timestamp, this.$tc('DaysShorthand') )
            case ETournamentStatus.FINISHED:
            case ETournamentStatus.CANCELED:
                return this.$tc('Finished') + ': ' + this.dateCreateInCurrentTimeZone( this.t.finished_timestamp )
            case ETournamentStatus.IN_PLAY:
                return this.$tc('Tournament.NextTourIn') + ':  ' + this.timeToTour//starts_in( this.t.next_timestamp, this.$tc('DaysShorthand') )
            default:
                return 'undefined'
        }
    }

    dateCreateInCurrentTimeZone(created_timestamp:number ) : string {
        const dt = (new Date(created_timestamp*1000));
        return [
            dt.getDate().toString().padStart(2,'0'),
            (dt.getMonth() + 1).toString().padStart(2,'0'),
            dt.getFullYear().toString().substr(-2)
        ].join('.')+
        ' '+
        [
            dt.getHours().toString().padStart(2,'0'),
            dt.getMinutes().toString().padStart(2,'0')
        ].join(':');
    }

    get status_string() {
        if ( ! this.t ) return ''
        switch ( this.t.status ) {
            case ETournamentStatus.ANNOUNCED:
                return this.$tc('Tournament.Announced')
            case ETournamentStatus.OPEN:
                return this.$tc('Tournament.Open')
            case ETournamentStatus.FINISHED:
                return this.$tc('Finished')
            case ETournamentStatus.CANCELED:
                return this.$tc('Canceled')
            case ETournamentStatus.IN_PLAY:
                return this.$tc('Ongoing')
            default:
                return 'undefined'
        }
    }

    get participants_ratio() {
        if ( ! this.t ) return ''
        return this.t.players_count + '/' + this.t.players_count_max
    }

    get rating_limitation() {
        if ( ! this.t ) return ''
        if ( this.t.player_rate_max <= 0 ) return this.$tc('No limitations')
        return this.t.player_rate_min.toString() + '-' + this.t.player_rate_max.toString()
    }
    
    on_info( game : any ) {
        this.$root.$emit(EDialogEvents.SHOW_GAME_INFO, game.uuid);       
    }

    can_watch( game : any ) {
        return game.status == EGameStatus.IN_PLAY
    }
    on_watch( game : any ) {        
        this.$router.push({
            path : this.localePath('/gameWatch'),
            query: { uuid : game.uuid, tournamentUuid: this.t ? this.t.uuid : '' }
        })        
    }
}

