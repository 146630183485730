
import Vue from 'vue'
import {Prop, Component} from "vue-property-decorator"
@Component({
     
})
export default class CreditsLack extends Vue {

    @Prop({default:true}) goBack!:boolean

    get has_shop() {
        return process.env.PHOENIX_GAME != '1' && process.env.STARZ_GAME != '1'
    }

    shop() {
        this.$emit('close');
        this.$router.push( this.localePath( 'shop' ) )
    }
    cancel() {
        this.$emit('close');
        if (this.goBack) {
            this.$router.go(-1)
        }
    }
}
