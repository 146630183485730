import { render, staticRenderFns } from "./GameInfo.vue?vue&type=template&id=332cae8e&scoped=true&lang=pug"
import script from "./GameInfo.vue?vue&type=script&lang=ts"
export * from "./GameInfo.vue?vue&type=script&lang=ts"
import style0 from "./GameInfo.vue?vue&type=style&index=0&id=332cae8e&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "332cae8e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoneyLabel: require('/artifacts/BGFrontend/components/Ui/MoneyLabel.vue').default,Avatar: require('/artifacts/BGFrontend/components/Ui/Avatar.vue').default,Window: require('/artifacts/BGFrontend/components/Window.vue').default})
