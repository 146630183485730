import { render, staticRenderFns } from "./TournamentInfo.vue?vue&type=template&id=2ffcde0f&scoped=true&lang=pug"
import script from "./TournamentInfo.vue?vue&type=script&lang=ts"
export * from "./TournamentInfo.vue?vue&type=script&lang=ts"
import style0 from "./TournamentInfo.vue?vue&type=style&index=0&id=2ffcde0f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ffcde0f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlayerLabel: require('/artifacts/BGFrontend/components/Ui/PlayerLabel.vue').default,BgButton: require('/artifacts/BGFrontend/components/Ui/BgButton.vue').default,Window: require('/artifacts/BGFrontend/components/Window.vue').default})
