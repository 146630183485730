// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ui/win/game_result/ng_win_alert_12_block.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/ui/win/game_result/ng_shields.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/ui/win/game_result/ng_crown.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".parent_div[data-v-48c4a279]{background-color:transparent;background-position:50%;background-size:30.5em auto;height:23em;margin:0 auto;min-height:23em;min-width:19.7857em;padding-top:1em;position:relative;width:30em}.pipe_shield[data-v-48c4a279]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat}.player_shield_stag[data-v-48c4a279]{background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:200% auto;display:block;height:23em;margin:0 auto;min-height:23em;min-width:19.7857em;padding-left:.265em;padding-top:5.705em;position:relative;text-align:center;width:19.7857em}.player_shield_stag_blue[data-v-48c4a279]{background-position:0}.player_shield_stag_red[data-v-48c4a279]{background-position:100%}.fit_avatar[data-v-48c4a279]{height:2.6428em!important;margin-right:.7142em!important;width:2.6428em!important}.crown[data-v-48c4a279]{animation:ng_crown-48c4a279 .5s steps(5) infinite;background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:0;background-repeat:no-repeat;background-size:auto 100%;height:10.6428em;left:4.7142em;position:absolute;top:-.7142em;width:10.6em}.player_name[data-v-48c4a279]{color:#fff;font-size:1.8em;margin:0 auto .2352em;overflow:hidden;padding-top:.98em;text-overflow:ellipsis;text-transform:uppercase;white-space:nowrap;width:8.9411em}.player_name[data-v-48c4a279],.rating[data-v-48c4a279]{display:block;line-height:1em;text-align:center}.rating[data-v-48c4a279]{color:#b0b3b8;font-size:1.6em;font-style:italic;margin:auto;position:relative;top:2.4em;width:12.6666em}@keyframes ng_crown-48c4a279{0%{background-position:0}to{background-position:-53.2142em}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
