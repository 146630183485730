
import Vue from 'vue'
import Game, { IGame, EGameType, EGameKind, EGameSpeed } from '~/modules/model/Types/Game';
import { print_timestamp } from '~/modules/utility/utils';
import { EBotSkillLevel } from '~/modules/model/Types/IPlayerSettings'

export default Vue.extend({
    props : [
        'gameUuid',
    ],
    data : ()=>({
        game : {} as Game,
    }),
    async created() {        
        if( ! this.gameUuid || ! this.gameUuid.length )
            return;
        
        const igame : IGame = await this.$store.dispatch( 'game/doViewGame', this.gameUuid )
        this.game = new Game( igame )
        console.log( 'GAME:', this.game )
    },
    methods : {
        type() : string {
            if ( ! this.game ) return 'undefined'
            switch ( this.game.type_id ) {
                case EGameType.TRAIN:
                    return this.$tc('Training')
                case EGameType.CUSTOM_DUEL:
                    return this.$tc('Custom duel')
                case EGameType.CUSTOM_MATCH:
                    return this.$tc('Custom match')
                case EGameType.TOURNAMENT:
                    return this.$tc('Game_type.tournament')
                default:
                    return 'undefined'
            }
        },
        backgammon() : string {
            if ( ! this.game ) return 'undefined'
            switch ( this.game.game_type_id ) {
                case EGameKind.LONG:
                    return this.$tc('Long backgammon')
                case EGameKind.SHORT:
                    return this.$tc('Short backgammon')
                default:
                    return 'undefined'
            }
        },
        speed() {
            return this.game ? this.game.gameSpeedTextId : undefined
        },

        getGameBet():string {
            //  h.bet_price + ' ' + $tc('CreditsBet')
            if (!this.game)
                return '';
            let r
            if(!this.game.currency)
                r = this.game.bet_price.toString();
            else
                r = this.game.currency.formatValue(this.game.bet_price, false);
            
            if ( typeof r === 'string' ) {
                r = parseFloat(r).toString()
            }
            return r
        },

        time_to_turn() {
            if (this.game && this.game.rules && this.game.rules.timeLimit && this.game.rules.timeLimit.step) {
                let step = this.game.rules.timeLimit.step;
                if (step < 60) {
                    return step + ' ' + this.$tc('Turn.sec')
                }
                let sec = step % 60;
                let min = Math.floor(step / 60);
                let res = min + ' ' + this.$tc('Turn.min');
                if (sec > 0) {
                    res += ' ' +  sec +' '+ this.$tc('Turn.sec')
                }
                return res;
            }
            else {
                return undefined
            }
        },
        name_bot(player_name: string) : string {
            if ( player_name.toLowerCase().startsWith( 'bot ' ) ) {
                player_name = player_name.substring( 4 )
                const t : { [ key : string ] : string } = {}
                t[ EBotSkillLevel.DUMMY ]           = this.$tc('Difficulty.Dummy')
                t[ EBotSkillLevel.NOVICE ]          = this.$tc('Difficulty.Novice')
                t[ EBotSkillLevel.SIMPLE ]          = this.$tc('Difficulty.Simple')
                t[ EBotSkillLevel.MIDDLE ]          = this.$tc('Difficulty.Medium')
                t[ EBotSkillLevel.ADVANCED ]        = this.$tc('Difficulty.Advanced')
                t[ EBotSkillLevel.PROFESSIONAL ]    = this.$tc('Difficulty.Professional')
                t[ EBotSkillLevel.EXPERT ]          = this.$tc('Difficulty.Expert')
                t[ EBotSkillLevel.MASTER ]          = this.$tc('Difficulty.Master')
                t[ EBotSkillLevel.CHAMPION ]        = this.$tc('Difficulty.Champion')
                t[ EBotSkillLevel.GURU ]            = this.$tc('Difficulty.Guru')
                for ( const key in t ) {
                    player_name = player_name.replace( new RegExp( key, "gi" ), t[ key ] )
                }
            }
            return player_name
        },

        name_1() {
            if ( ! this.game || ! this.game.playerA ) return ''

            return this.name_bot(this.game.playerA.name)
        },
        name_2() {
            if ( ! this.game || ! this.game.playerB ) return ''
            return this.name_bot(this.game.playerB.name)
        },
        double_cube() {
            return this.game && this.game.rules && this.game.rules.doubleCube.enabled ? this.$tc('Yes') : this.$tc('No')
        },
        cube_auto() {
            return this.game && this.game.rules && this.game.rules.doubleCube.autoDouble.enabled ? this.$tc('Yes') : this.$tc('No')
        },
        cube_auto_limit() {
            return this.game && this.game.rules && this.game.rules.doubleCube.autoDouble.limit
        },
        biver() {
            return this.game && this.game.rules && this.game.rules.doubleCube.biver ? this.$tc('Yes') : this.$tc('No')
        },
        paritet() {
            return this.game && this.game.rules && this.game.rules.paritet && this.game.rules.paritet.enabled ? this.$tc('Yes') : this.$tc('No')
        },
        mars() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.mars ? this.$tc('Yes') : this.$tc('No')
        },
        koks() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.koks ? this.$tc('Yes') : this.$tc('No')
        },
        jakobi() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.jakobi ? this.$tc('Yes') : this.$tc('No')
        },
        crawford() {
            return this.game && this.game.rules && this.game.rules.modifications && this.game.rules.modifications.crawford ? this.$tc('Yes') : this.$tc('No')
        },
        dateCreateInCurrentTimeZone(h: Game) : string {
            if (h && h.created_timestamp) {
                const dt = (new Date(h.created_timestamp*1000));
                return [
                    dt.getDate().toString().padStart(2,'0'),
                    (dt.getMonth() + 1).toString().padStart(2,'0'),
                    dt.getFullYear().toString().substr(-2)
                ].join('.')+
                ' '+
                [
                    dt.getHours().toString().padStart(2,'0'),
                    dt.getMinutes().toString().padStart(2,'0')
                ].join(':');
                //return print_timestamp(h.created_timestamp)
            }
            else {
                return '';
            }
        },
       gameCaption() {
            if (this.game) {
                let caption = this.game.caption;
                if (caption.indexOf('{Round #}') > -1) {
                    caption = caption.replace(/{Round #}/, this.$tc('Tournament.Tour') + ' ');
                }
                return caption;
            }
            return '';
        }
    },
})
