import { render, staticRenderFns } from "./PlayerStats.vue?vue&type=template&id=25fc52b6&scoped=true&lang=pug"
import script from "./PlayerStats.vue?vue&type=script&lang=ts"
export * from "./PlayerStats.vue?vue&type=script&lang=ts"
import style0 from "./PlayerStats.vue?vue&type=style&index=0&id=25fc52b6&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fc52b6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlayerStag: require('/artifacts/BGFrontend/components/Ui/PlayerStag.vue').default,AbstractPlayerStats: require('/artifacts/BGFrontend/components/Ui/AbstractPlayerStats.vue').default,Window: require('/artifacts/BGFrontend/components/Window.vue').default})
